import {
	handleActions,
	combineActions,
	ActionFunctionAny,
	Action,
} from 'redux-actions';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import type { State, Payload } from '../../dtos/payment/payment-reducer.dto';

import {
	paymentAddPaymentCardAsyncAction,
	paymentGetPaymentCardsAsyncAction,
	paymentGetPaymentHistoryAsyncAction,
	paymentMakePaymentAsyncAction,
	paymentMakePayment2AsyncAction,
	paymentGetInvoicesAsyncAction,
	paymentUpdatePaymentCardAsyncAction,
	paymentDeletePaymentCardAsyncAction,
	paymentGetMyBillsAsyncAction,
	paymentGetPaymentTransactionAsyncAction,
	paymentGetReceiptsAsyncAction,
} from './paymentActions';

const initialState: State = {
	cards: {
		loading: false,
		success: false,
		error: '',
		data: [],
	},

	action: {
		loading: false,
		success: false,
		error: '',
	},

	paymentHistory: {
		loading: false,
		success: false,
		error: '',
		data: [],
	},

	payment: {
		loading: false,
		success: false,
		error: '',
		data: {},
	},

	mybills: {
		loading: false,
		success: false,
		error: '',
		data: [],
	},

	transactions: {
		loading: false,
		success: false,
		error: '',
		data: {},
	},

	receipts: {
		loading: false,
		success: false,
		error: '',
		data: [],
	},

	invoices: {
		loading: false,
		success: false,
		error: '',
		data: null,
	},
};

const reducerMap = Object.fromEntries(
	new Map<
		ActionFunctionAny<Action<any>> | ReturnType<typeof combineActions>,
		(state: State, { payload }: { payload: Payload }) => any
	>([
		[
			paymentGetInvoicesAsyncAction.request,
			(state) => ({
				...state,
				invoices: {
					...state.invoices,
					loading: true,
				},
			}),
		],
		[
			paymentGetInvoicesAsyncAction.success,
			(state, { payload }) => ({
				...state,
				invoices: {
					...state.invoices,
					success: true,
					loading: false,
					data: payload.data,
				},
			}),
		],
		[
			paymentGetInvoicesAsyncAction.failure,
			(state, { payload }) => ({
				invoices: {
					...state.invoices,
					loading: false,
					success: false,
					error: payload.error_msg,
				},
			}),
		],

		[
			paymentGetPaymentCardsAsyncAction.request,
			(state) => ({
				...state,
				cards: {
					...state.cards,
					loading: true,
					success: false,
				},
			}),
		],
		[
			paymentGetPaymentCardsAsyncAction.success,
			(state, { payload }) => ({
				...state,
				cards: {
					...state.cards,
					loading: false,
					success: true,
					data: payload.data,
				},
			}),
		],
		[
			paymentGetPaymentCardsAsyncAction.failure,
			(state, { payload }) => ({
				...state,
				cards: {
					...state.cards,
					loading: false,
					success: false,
					error: payload.data,
				},
			}),
		],

		[
			combineActions(
				paymentAddPaymentCardAsyncAction.request,
				paymentUpdatePaymentCardAsyncAction.request,
				paymentDeletePaymentCardAsyncAction.request
			),
			(state) => ({
				...state,
				action: {
					...state.action,
					loading: true,
				},
			}),
		],
		[
			combineActions(
				paymentAddPaymentCardAsyncAction.success,
				paymentUpdatePaymentCardAsyncAction.success,
				paymentDeletePaymentCardAsyncAction.success
			),
			(state, { payload }) => ({
				...state,
				action: {
					...state.action,
					success: payload,
					loading: false,
				},
			}),
		],
		[
			combineActions(
				paymentAddPaymentCardAsyncAction.failure,
				paymentUpdatePaymentCardAsyncAction.failure,
				paymentDeletePaymentCardAsyncAction.failure
			),
			(state, { payload }) => ({
				...state,
				action: {
					...state.action,
					error: payload.error_msg,
					success: false,
					loading: false,
				},
			}),
		],

		[
			paymentGetPaymentHistoryAsyncAction.request,
			(state) => ({
				...state,
				paymentHistory: {
					...state.paymentHistory,
					loading: true,
				},
			}),
		],
		[
			paymentGetPaymentHistoryAsyncAction.success,
			(state, { payload }) => ({
				...state,
				paymentHistory: {
					...state.paymentHistory,
					loading: false,
					success: true,
					data: payload.data,
				},
			}),
		],
		[
			paymentGetPaymentHistoryAsyncAction.failure,
			(state, { payload }) => ({
				...state,
				paymentHistory: {
					...state.paymentHistory,
					loading: false,
					success: false,
					error: payload.error_msg,
				},
			}),
		],

		[
			combineActions(
				paymentMakePaymentAsyncAction.request,
				paymentMakePayment2AsyncAction.request
			),
			(state) => ({
				...state,
				payment: {
					...state.payment,
					loading: true,
					success: false,
					error: '',
				},
			}),
		],
		[
			combineActions(
				paymentMakePaymentAsyncAction.success,
				paymentMakePayment2AsyncAction.success
			),
			(state, { payload }) => ({
				...state,
				payment: {
					...state.payment,
					loading: false,
					success: true,
					data: payload.data,

				},
			}),
		],
		[
			combineActions(
				paymentMakePaymentAsyncAction.failure,
				paymentMakePayment2AsyncAction.failure
			),
			(state, { payload }) => ({
				...state,
				payment: {
					...state.payment,
					loading: false,
					success: false,
					data: payload.data,
					error: payload.error_msg || '',
					error_code:payload.error_code ||0
				},
			}),
		],

		[
			paymentGetMyBillsAsyncAction.request,
			(state) => ({
				...state,
				mybills: {
					...state.mybills,
					loading: true,
					success: false,
					error: '',
				},
			}),
		],
		[
			paymentGetMyBillsAsyncAction.success,
			(state, { payload }) => ({
				...state,
				mybills: {
					...state.mybills,
					loading: false,
					success: true,
					error: '',
					data: payload.data,
				},
			}),
		],
		[
			paymentGetMyBillsAsyncAction.failure,
			(state, { payload }) => ({
				...state,
				mybills: {
					...state.mybills,
					loading: false,
					success: false,
					error: payload.error_msg,
					data: [],
				},
			}),
		],

		[
			paymentGetPaymentTransactionAsyncAction.request,
			(state) => ({
				...state,
				transactions: {
					...state.transactions,
					loading: true,
					success: false,
					error: '',
				},
			}),
		],
		[
			paymentGetPaymentTransactionAsyncAction.success,
			(state, { payload }) => ({
				...state,
				transactions: {
					...state.transactions,
					loading: false,
					success: true,
					error: '',
					data: payload.data,
				},
			}),
		],
		[
			paymentGetPaymentTransactionAsyncAction.failure,
			(state, { payload }) => ({
				...state,
				transactions: {
					...state.transactions,
					loading: false,
					success: false,
					error: payload.error_msg,
					data: [],
				},
			}),
		],

		[
			paymentGetReceiptsAsyncAction.request,
			(state) => ({
				...state,
				receipts: {
					...state.receipts,
					loading: true,
					success: false,
					error: '',
				},
			}),
		],
		[
			paymentGetReceiptsAsyncAction.success,
			(state, { payload }) => ({
				...state,
				receipts: {
					...state.receipts,
					loading: false,
					success: true,
					error: '',
					data: payload.data,
				},
			}),
		],
		[
			paymentGetReceiptsAsyncAction.failure,
			(state, { payload }) => ({
				...state,
				receipts: {
					...state.receipts,
					loading: false,
					success: false,
					error: payload.error_msg,
					data: [],
				},
			}),
		],
	])
);

export const paymentReducer = persistReducer(
	{
		key: 'payment',
		storage,
		whitelist: ['mybills', 'invoices', 'transactions', 'receipts'],
		blacklist: ['cards', 'action', 'paymentHistory'],
	},
	handleActions(reducerMap, initialState)
);
